<template>
  <div class="bg-white modal-card rounded" style="width:auto">
    <div class="bg-primary-100 p-4 text-xl">{{ modalTitle }}</div>
    <div class="grid grid-cols-4 gap-3 p-5 overflow-y-auto" style="max-height: 80vh;">
      <div class="col-span-4 sm:col-span-2">
        <t-input-group label="Événement">
          <t-input v-model="eventForm.title" type="text" id="title"/>
        </t-input-group>
      </div>
      <div class="col-span-4 sm:col-span-2">
        <t-input-group label="Date">
          <t-datepicker
            timepicker
            v-model="date"
            dateFormat="Y-m-d H:i:S"
            userFormat="j F, Y H:i"
            clearable
            required
          />
        </t-input-group>
      </div>
      <div class="col-span-4">
        <t-input-group label="Description">
          <t-textarea rows="6" name="description" v-model="eventForm.description"></t-textarea>
        </t-input-group>
      </div>

      <div class="col-span-4">
        <t-input-group label="Contenus à créer">
          <t-textarea rows="6" name="description" v-model="eventForm.content_to_create"></t-textarea>
        </t-input-group>
      </div>

      <div class="col-span-4 sm:col-span-2">
        <t-input-group label="Documents">
          <b-upload v-model="files" multiple @change="fileError = false">
            <a class="button" v-bind:class="fileError && files.length === 0 ? 'is-danger' : 'is-primary'">
              <b-icon icon="upload"></b-icon>
              <span>Ajouter des documents</span>
            </a>
          </b-upload>
        </t-input-group>
      </div>
      <div v-if="files.length > 0" class="col-span-4 sm:col-span-2">
        <ul>
          <li v-for="(file, i) in files" :key="i" class="flex justify-between pr-3">
            <span class="" v-if="file.name">- {{ file.name }}</span>
            <button type="button" @click.prevent="removeFile(i)">
              <i class="fas fa-trash text-red-600 cursor-pointer"></i>
            </button>
          </li>
        </ul>
      </div>
      <span class="col-span-4 font-bold block text-red-500" v-if="fileError">{{ fileError }}</span>
      <div class="col-span-4 flex items-center">
        <label class="text-right" for="notify">Notifier l'équipe {{ domain.label }}</label>
        <t-toggle class="ml-4" v-model="notify"/>
      </div>
    </div>
    <div class="bg-primary-100 p-4 flex justify-end gap-3 mt-3">
      <t-button class="m-0" variant="secondary" @click="$parent.close()">Annuler</t-button>
      <t-button @click="sendFile()">Envoyer</t-button>
    </div>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { format } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  name: 'app-surveys-one',
  props: ['currentEvent'],
  data () {
    return {
      eventForm: {
        title: '',
        description: '',
        content_to_create: ''
      },
      date: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      files: [],
      file: {},
      modalTitle: `Création d'un évènement`,
      fileError: null,
      notify: false
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      'roles'
    ])
  },
  beforeMount () {
    if (this.currentEvent) {
      this.eventForm.title = this.currentEvent.title
      this.eventForm.description = this.currentEvent.description
      this.eventForm.content_to_create = this.currentEvent.content_to_create
      this.date = this.currentEvent.event_date
      this.modalTitle = `Modification d'un évènement`
      this.files = this.currentEvent.files
    }
  },
  methods: {
    fileSize (size) {
      if (size) {
        const sizes = ['octets', 'ko', 'Mo', 'Go']
        const i = Math.floor(Math.log(size) / Math.log(1024))
        return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`
      } else {
        return `O o`
      }
    },
    sendFile () {
      let formData = this.setFormData()
      let error = false
      if (this.currentEvent) {
        http.post(`/events/${this.currentEvent.uuid}`, formData, {
          headers: {
            'Content-Type': `multipart/form-data`
          }
        }).then(({ data }) => {
          this.$emit('updated', data)
        }).catch(err => {
          error = true
          if (err.response.status === 422) {
            this.$buefy.notification.open({
              duration: 3500,
              message: 'Une erreur est survenue lors de l\'édition de cet article, veuillez vérifier les informations saisies',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          }
        }).finally(() => {
          if (!error) this.$parent.close()
        })
      } else {
        http.post('/events', formData, {
          headers: {
            'Content-Type': `multipart/form-data`
          }
        }).then(({ data }) => {
          this.notify = true // notify team no matter what, delete line on demand
          if (this.notify) {
            http.post('/events/notify-team', {
              'domain': data.domain.label,
              'event-title': data.title,
              'event-date': data.event_date,
              'description': data.description,
              'content-to-create': data.content_to_create
            })
          }
          this.$emit('created', data)
        }).catch(err => {
          error = true
          if (err.response.status === 422) {
            this.$buefy.notification.open({
              duration: 3500,
              message: 'Une erreur est survenue lors de la création de cet article, veuillez vérifier les informations saisies',
              position: 'is-bottom-left',
              type: 'is-warning',
              hasIcon: true,
              icon: 'server'
            })
          }
        }).finally(() => {
          if (!error) this.$parent.close()
        })
      }
    },
    setFormData () {
      const formData = new FormData()
      const { ...data } = this.eventForm
      for (const prop in data) {
        formData.append(prop, data[prop])
      }
      if (this.currentEvent) {
        this.currentEvent.files.map(file => formData.append('keepFiles[]', file.uuid))
      }
      formData.append('event_date', this.date)
      this.files.map(file => formData.append('files[]', file))
      return formData
    },

    removeFile (i) {
      this.files.splice(i, 1)
    }
  }
}

</script>
