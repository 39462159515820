<template>
  <div class="app-events">
    <PageHeader
      title="Calendrier"
      description="Planification des évènements"
      :button="{
        text: 'Ajouter un évènement',
        icon: 'fas fa-plus-circle',
        action: () => addEvent()
      }"
    />
    <PageContent>
      <MVPTable :gridData="events" :options="gridOptions" @rowDoubleClicked="editDoc"/>
    </PageContent>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import { http } from '@/services/api.service'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import OneEvent from './Modals/One'
import { orderBy, reverse } from 'lodash'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import ListRowDetail from './ListRowDetail.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    PageHeader,
    PageContent,
    MVPTable,
    ListRowDetail, //eslint-disable-line
  },
  data () {
    return {
      loading: false,
      events: null,
      gridOptions: {
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        columnDefs: [
          {
            cellRenderer: 'agGroupCellRenderer',
            width: 20,
            suppressMenu: true
          },
          {
            headerName: 'Date',
            field: 'event_date',
            type: ['dateColumnFilterable'],
            cellRenderer: 'DateCellRenderer'
          },
          {
            headerName: 'Événement',
            field: 'title',
            filter: 'agSetColumnFilter',
            sortable: true
          },
          {
            headerName: 'Descriptif de l\'événement',
            field: 'description',
            sortable: true
          },
          {
            headerName: 'Pièces jointes',
            field: 'files',
            cellRenderer: 'FilesCellRenderer',
            sortable: true
          },
          {
            headerName: 'Contenus à créer',
            field: 'content_to_create',
            sortable: true
          },
          {
            headerName: 'Supprimer',
            cellRenderer: 'IconCellRenderer',
            cellRendererParams: {
              iconClass: 'fas fa-trash text-red-600',
              onClick: this.deleteDoc
            },
            hide: !this.hasEditionRights,
            width: 90
          }
        ]
      }
    }
  },
  watch: {
    domain () {
      this.getEvents()
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      'roles'
    ]),
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    },
    filteredEvents () {
      if (this.sortBy) {
        if (this.reverse) {
          return reverse(orderBy(this.events, doc => {
            return doc[this.sortByLabel]
          }))
        }
        return orderBy(this.events, doc => {
          return doc[this.sortByLabel]
        })
      }
      return this.events
    }
  },
  mounted () {
    this.getEvents()
  },
  methods: {
    getEvents () {
      this.loading = true
      http.get('/events').then(res => {
        this.loading = false
        this.events = res.data
      }).catch(err => {
        console.log(err)
        this.loading = false
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Nos serveurs rencontrent des difficultés à récuperer la liste des évènements',
          position: 'is-bottom-left',
          type: 'is-warning',
          hasIcon: true,
          icon: 'server'
        })
      })
    },
    addEvent () {
      this.$buefy.modal.open({
        parent: this,
        component: OneEvent,
        hasModalCard: true,
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          created: (newDoc) => {
            this.events.push(newDoc)
            this.$buefy.notification.open({
              duration: 3000,
              message: `L'évènement <strong>${newDoc.title}</strong> a bien été créé`,
              position: 'is-bottom-right',
              type: 'is-success',
              hasIcon: false
            })
          }
        }
      })
    },
    editDoc (event) {
      if (!this.hasEditionRights) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Vous n\'avez pas les droits pour modifier un évènement',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
        return
      }
      this.$buefy.modal.open({
        parent: this,
        component: OneEvent,
        hasModalCard: true,
        props: { currentEvent: event.data },
        width: 800,
        destroyOnHide: false,
        ariaRole: 'dialog',
        ariaModal: true,
        events: {
          updated: (updatedDoc) => {
            this.events[event.rowIndex] = updatedDoc
            this.events.splice(event.rowIndex, 1, updatedDoc)
            this.$buefy.notification.open({
              duration: 3000,
              message: `L'évènement <strong>${updatedDoc.title}</strong> a bien été modifié`,
              position: 'is-bottom-right',
              type: 'is-success',
              hasIcon: false
            })
          }
        }
      })
    },
    deleteDoc (event) {
      if (!this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))) {
        this.$buefy.notification.open({
          duration: 3000,
          message: 'Vous n\'avez pas les droits pour supprimer un évènement',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
        return
      }
      if (confirm(`Êtes-vous sûr de vouloir supprimer ce évènement : ${event.title} ?`)) {
        this.loading = true
        http.delete(`/events/${event.uuid}`).then(res => {
          this.loading = false
          this.events = this.events.filter(doc => {
            return doc.uuid !== event.uuid
          })
          this.$buefy.notification.open({
            duration: 3000,
            message: `L'évènement <strong>${event.title}</strong> a bien été supprimé`,
            position: 'is-bottom-right',
            type: 'is-success',
            hasIcon: false
          })
        })
      } else {
        this.$buefy.notification.open({
          duration: 3000,
          message: `Annulation de la suppression de l'évènement <strong>${event.title}</strong>`,
          position: 'is-bottom-right',
          type: 'is-warning',
          hasIcon: false
        })
      }
    }
  }
}
</script>
